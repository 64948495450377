import get from 'lodash/get';
import invoke from 'lodash/invoke';

import { mapActions, mapState } from 'vuex';
import { STORE_REQUEST_STATUS } from '@emobg/web-utils';
import {
  ACTIONS,
  NAMESPACE,
  SCOPES,
  store,
} from './NotificationCenterModule';

export const {
  fetchSubscriptions,
  isLoading,
  isSaving,
  registerModule,
  setSubscriptionGroups,
  subscriptionGroups,
} = {
  ...mapState(NAMESPACE, {
    isLoading: state => get(state, `${SCOPES.fetching}.STATUS.${STORE_REQUEST_STATUS.loading}`, false),
    isSaving: state => get(state, `${SCOPES.updating}.STATUS.${STORE_REQUEST_STATUS.loading}`, false),
    subscriptionGroups: state => get(state, `${SCOPES.fetching}.data.data`, []),
  }),
  ...mapActions(NAMESPACE, {
    fetchSubscriptions: ACTIONS.fetchSubscriptions,
    setSubscriptionGroups: ACTIONS.setSubscriptions,
  }),
  registerModule: component => {
    const hasModule = invoke(component, '$store.hasModule', NAMESPACE);
    if (!hasModule) {
      component.$store.registerModule(NAMESPACE, store);
    }
  },
};
