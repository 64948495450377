import {
  mapActions,
  mapMutations,
  mapState,
} from 'vuex';
import get from 'lodash/get';
import UserSignUpModule from '@/vue/refactor/core/store/user';

export const {
  businessData,
  company,
  createEmployee,
  createEmployeeFromInvitation,
  createUser,
  genericInfo,
  isEmployee,
  isInviteValid,
  isPhoneNumberValid,
  ownsEmployeeData,
  promoCode,
  setFirstName,
  setIdNumber,
  setIsPhoneNumberValid,
  setLastName,
  setPromoCode,
  setReceiveNews,
  setTaxNumber,
  setTitle,
  signUpData,
  stepsDone,
  setForceErrorOnWrongPromoCode,
} = {
  ...mapState(UserSignUpModule.NAMESPACE, {
    signUpData: state => state.data,
    businessData: state => state.b2b,
    stepsDone: state => state.done,
    genericInfo: state => state.info,
    company: state => get(state, 'b2b.company'),
    ownsEmployeeData: state => get(state, 'b2b.company.ownsEmployeeData'),
    promoCode: state => get(state, 'data.promoCode'),
    isInviteValid: state => get(state, 'b2b.invitation.valid_invite'),
    isEmployee: state => get(state, 'b2b.isEmployee'),
    isPhoneNumberValid: state => get(state, 'validation.isPhoneNumberValid'),
  }),

  ...mapMutations(UserSignUpModule.NAMESPACE, {
    setTitle: 'title',
    setFirstName: 'firstName',
    setLastName: 'lastName',
    setTaxNumber: 'taxNumber',
    setIdNumber: 'idNumber',
    setPromoCode: 'promoCode',
    setReceiveNews: 'receiveNews',
    setIsPhoneNumberValid: 'setIsPhonNumberValid',
    setForceErrorOnWrongPromoCode: 'setForceErrorOnWrongPromoCode',
  }),

  ...mapActions(UserSignUpModule.NAMESPACE, {
    createUser: 'createUser',
    createEmployee: 'B2BCreateEmployee',
    createEmployeeFromInvitation: 'B2BCreateEmployeeFromInvitation',
  }),
};
