import { mapState } from 'vuex';
import GenericSignUpModule from '@/vue/refactor/core/store/generic';

export const {
  personTitles,
  countries,
} = {
  ...mapState(GenericSignUpModule.NAMESPACE, {
    personTitles: state => state.titles,
    countries: state => state.countries,
  }),
};
