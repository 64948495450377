import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import map from 'lodash/map';

import { external } from '@emobg/web-api-client';
import {
  STORE_REQUEST_STATUS,
  createCustomStore,
  withScopes,
} from '@emobg/web-utils';
import { replaceLinks } from './helpers';

export const NAMESPACE = 'NOTIFICATION_CENTER';

export const ACTIONS = {
  fetchSubscriptions: 'fetchSubscriptions',
  setSubscriptions: 'setSubscriptions',
  saveSubscriptions: 'saveSubscriptions',
};

const MUTATIONS = {
  setSubscriptions: 'setSubscriptions',
  setStatus: 'setStatus',
};

export const SCOPES = {
  fetching: 'fetching',
  updating: 'updating',
};

export const store = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, ({
  mutations: {
    [MUTATIONS.setSubscriptions](state, subscriptions) {
      state[SCOPES.fetching].data = {
        data: subscriptions,
      };
    },
  },

  actions: {
    async [ACTIONS.fetchSubscriptions]({ commit, dispatch }, payload) {
      if (isArray(payload) || !isObject(payload)) {
        return;
      }

      const { trackingId } = payload;

      const response = await runAsyncFlow(commit, {
        request: external.userCompanySubscriptions.getSubscriptions,
        params: [trackingId],
        scope: SCOPES.fetching,
      });

      const parsedResponse = replaceLinks(get(response, 'data'));

      dispatch(ACTIONS.setSubscriptions, parsedResponse);
    },
    [ACTIONS.setSubscriptions]({ commit }, payload) {
      const subscriptions = payload || [];

      commit(MUTATIONS.setSubscriptions, subscriptions);
    },
    [ACTIONS.saveSubscriptions]({ commit }, payload) {
      const {
        trackingId,
        subscriptions,
      } = payload || {};

      const subscriptionsPayload = map(subscriptions, ({ uuid, checked }) => ({ uuid, checked }));

      commit(MUTATIONS.setStatus, {
        scope: SCOPES.updating,
        value: STORE_REQUEST_STATUS.loading,
      });

      return runAsyncFlow(commit, {
        request: external.userCompanySubscriptions.putSubscriptions,
        params: [trackingId, subscriptionsPayload],
        scope: SCOPES.updating,
      });
    },
  },
})));
