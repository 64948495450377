import get from 'lodash/get';
import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import invoke from 'lodash/invoke';

/**
 * @typedef {Object} ExternalLink
 * @property {string} placeholder - String to be replaced
 * @property {string} link
 */

/**
 * @typedef {Object} SubscriptionGroup
 * @property {string} uuid
 * @property {string} label
 * @property {string} description
 * @property {ExternalLink[]} externalLinks
 * @property {boolean} checked
 */

/**
 * Replace every placeholder with an external hyperlink.
 * If link or placeholder are falsy, it doesn't replace anything.
 * @param {SubscriptionGroup[]} subscriptionGroups
 * @return {SubscriptionGroup[]}
 */
export const replaceLinks = subscriptionGroups => {
  if (!isArray(subscriptionGroups)) {
    return [];
  }

  return map(subscriptionGroups, group => {
    const externalLinks = get(group, 'externalLinks');

    const subscriptionGroup = reduce(externalLinks, (groupUpdated, externalLink) => {
      const placeholder = get(externalLink, 'placeholder');
      const link = get(externalLink, 'link');

      const isLinkValid = placeholder && link;

      if (!isLinkValid) {
        return groupUpdated;
      }

      const regex = new RegExp(placeholder, 'g');
      const hyperlink = `<a href="${link}" target="_blank">${placeholder}</a>`;
      const description = invoke(groupUpdated, 'description.replace', regex, hyperlink) || '';

      return {
        ...groupUpdated,
        description,
      };
    }, group);

    return subscriptionGroup;
  });
};
